@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');

:root{
  --purple: #5A509C;
  --green: #97BF10;
  --pink: #DF037A;
  --pink-light: #df03786e;
  --pink-lightest: #df037817;
  --orange: #FCA702;
}

*{
  font-family: 'Public Sans', sans-serif;
}

a{
  text-decoration: none;
  color: inherit;
}